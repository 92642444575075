export const HERO_TEXTS = {
  organic: {
    title: 'Contrate agora mesmo um plano Pós-pago!',
    subtitle:
      'Com nosso plano pós-pago, você acessa dados atualizados sobre seus clientes, garantindo decisões mais seguras e assertivas para o seu negócio.'
  },
  paid: {
    title: 'Evite surpresas!',
    subtitle:
      'Consulte CNPJ e CPF dos seus clientes e saiba com quem está negociando.'
  }
}
