'use client'

import { useEffect } from 'react'

import { useExperiment } from 'rvbr-www/red-testing'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'

import { useProposalContext } from '@/context/ProposalContext'

import usePageLayout from '@/hooks/useLayout'
import useLazyLoad from '@/hooks/useLazyLoad'

export const usePageStructure = () => {
  usePageLayout()

  const { activateExperiment } = useExperiment('form_v2')
  const { isPaywallOpen } = useProposalContext()

  const lazyLoad = useLazyLoad(50)
  const isMobile = useMediaQuery('screen and (max-width: 1024px)')

  const buildType = NEXT_PUBLIC_BUILD_TYPE ?? 'paid' // eslint-disable-line no-undef

  useEffect(() => {
    activateExperiment()
  }, [])

  return {
    buildType,
    lazyLoad,
    isMobile,
    isPaywallOpen
  }
}
