import dynamic from 'next/dynamic'

import { RedTesting } from 'rvbr-www/red-testing'

import { useConditionalRendering } from '@/hooks/useConditionalRendering'

import { Hero } from '@/sections'

import { If, Banner, BannerButtons } from '@/components'

import { usePageStructure } from './usePageStructure'

import { HERO_TEXTS } from './constants'

const Footer = dynamic(() =>
  import('@/components/Footer/Footer').then((mod) => mod.Footer)
)

const Paywall = dynamic(() =>
  import('@/components/Paywall/Paywall').then((mod) => mod.Paywall)
)

const Contact = dynamic(() =>
  import('@/sections/Contact/Contact').then((mod) => mod.Contact)
)

const Help = dynamic(() =>
  import('@/sections/Help/Help').then((mod) => mod.Help)
)

const Hiring = dynamic(() =>
  import('@/sections/Hiring/Hiring').then((mod) => mod.Hiring)
)

const Plans = dynamic(() =>
  import('@/sections/Plans/Plans').then((mod) => mod.Plans)
)

const BusinessSolutions = dynamic(() =>
  import('../../sections/BusinessSolutions/BusinessSolutions').then(
    (mod) => mod.BusinessSolutions
  )
)

const PersonalSolutions = dynamic(() =>
  import('../../sections/PersonalSolutions/PersonalSolutions').then(
    (mod) => mod.PersonalSolutions
  )
)

const Payment = dynamic(() =>
  import('../../sections/Payment/Payment').then((mod) => mod.Payment)
)

const PostpaidAdvantages = dynamic(() =>
  import('../../sections/PostpaidAdvantages/PostpaidAdvantages').then(
    (mod) => mod.PostpaidAdvantages
  )
)

export const PageStructure = () => {
  const { buildType, lazyLoad, isMobile, isPaywallOpen } = usePageStructure()
  const { isAffiliate } = useConditionalRendering()

  const redTestingServices = (
    <>
      <RedTesting
        experimentId="form_v2"
        control={<></>}
        variants={[
          <>
            <Plans key="plans-form_v2--b" />
            <BusinessSolutions />
          </>
        ]}
      />
    </>
  )

  return (
    <main>
      {isPaywallOpen && <Paywall />}

      <Banner
        isAnimated
        align="center"
        text="A Boa Vista mudou e agora é Equifax | BoaVista!"
      />

      <Hero
        variant={buildType}
        title={HERO_TEXTS[buildType].title}
        subtitle={HERO_TEXTS[buildType].subtitle}
      />

      {!isMobile && redTestingServices}

      <If condition={lazyLoad}>
        {isMobile && redTestingServices}

        <PostpaidAdvantages />
        <Hiring />
        <Payment />
        <PersonalSolutions />
        <Help />
        <Contact />
        <Footer />
      </If>

      <If condition={!isAffiliate}>
        <Banner
          isFixed
          hasBorder
          hideTextOnMobile
          text="Central de vendas Equifax | BoaVista"
        >
          <BannerButtons />
        </Banner>
      </If>
    </main>
  )
}
