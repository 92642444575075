import { useState, useEffect } from 'react'
import isClient from '@equifax-ui/utils/browser/isClient'

const useLazyLoad = () => {
  const [lazyLoad, setLazyLoad] = useState(false)

  useEffect(() => {
    if (isClient()) {
      // Any event of scroll or click triggers the lazy load of all the components
      window.addEventListener('scroll', () => {
        setLazyLoad(true)
      })
      window.addEventListener('click', () => {
        setLazyLoad(true)
      })
    }
  }, [])

  return lazyLoad
}

export default useLazyLoad
